import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/views/layout';
import pressRouter from './modules/pressCenter';
import operationRouter from './modules/operationCenter';
import accountRouter from './modules/accountCenter';

Vue.use(Router)

//所有权限通用路由表 
//如首页和登录页和一些不用权限的公用页面
export const constantRouterMap = [
  {
    path: '/login',
    name: 'Login',
    hideInMenu: true,
    component: () => import('../views/login')
  }
]


//异步挂载的路由
//动态需要根据权限加载的路由表 
export const asyncRouterMap= [
  pressRouter,
  operationRouter,
  accountRouter,
  {
    path: '*',
    redirect: '/404',
    hideInMenu: true,
  },
  {
    path: '/404',
    name: '404',
    hideInMenu: true,
    component: () => import('../views/errorPage/404')
  }
]

const createRouter = () => new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router