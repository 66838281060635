<template>
  <div class="sidebar-container">
    <div class="logo">天下网商</div>
    <el-scrollbar>
      <el-menu
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="true"
        mode="vertical"
        router
        :default-active="this.$route.meta.columnId">
          <submenu v-for="(route,index) in menuData" :key="index" :item="route" :base-path="route.path"></submenu>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import Submenu from './Submenu'
import { mapGetters } from 'vuex'
import variables from '@/assets/scss/variables.scss'
import {deepClone} from '@/utils/tool'

export default {
  data() {
    return {
    }
  },
  components: {
    Submenu
  },
  computed: {
    ...mapGetters(['sidebar','permission_routers','menu']),
    isCollapse() {
      return !this.sidebar.opened
    },
    variables() {
      return variables
    },
    menuData() {
      return this.menu
    }
  }
}
</script>
