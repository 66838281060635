import axios from 'axios';
import { Message } from 'element-ui';
import store from '../store';


// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API, // api的base_url 读取config配置文件
  timeout: 60000                  // 请求超时时间
});



// request拦截器
service.interceptors.request.use(config => {
  if (store.getters.token) {
    config.headers.Authorization = store.getters.token
  }
  return config;
}, error => {
  Promise.reject(error);
})

// 是否正在刷新的标记
let isRefreshing = false
// 重试队列，每一项将是一个待执行的函数形式
let requests = []

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.statusCode && res.statusCode !== 1000) {
      Message.error(res.message)
      return Promise.reject('error')
    } else {
      return res
    }
  },
  error => {
    // console.log('err')
    // console.log(error.response)

    if(error && error.response){
      if(error.response.status == 404) {
        Message.error('请求的资源不存在');
      } else if(error.response.data && error.response.data.statusCode && error.response.data.statusCode == 403) { 
        const config = error.response.config
        // token失效
        if(!isRefreshing) {
          isRefreshing = true
          return axios.post(`${process.env.VUE_APP_API}/api/auth/refreshToken`, {refreshToken: store.getters.refreshToken}).then(res => {
            store.dispatch('auth/refreshToken',res.data)
            // 已经刷新了token，将所有队列中的请求进行重试
            isRefreshing = false
            requests.forEach(cb => cb())
            requests = []
            return service(config)
            
          }).catch(res => {
            store.dispatch('auth/logout').then(() => {
              Message({
                message: '登录失效,请重新登录',
                type: 'warning',
                duration: 2000,
                onClose: () => {
                  location.reload()
                  isRefreshing = false
                }
              })
            })
          }).finally(() => {
            isRefreshing = false
          })
        } else {
          // 正在刷新token，将返回一个未执行resolve的promise
          return new Promise((resolve) => {
            // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
            requests.push(() => {
              resolve(service(config))
            })
          })
        }
      } else {
        Message.error(`连接错误${error.response.status}`);
      }
    } else {
      // 超时处理
      if (error.includes('timeout')) {
        Message.error('服务器响应超时，请刷新当前页')
      }
      Message.error('连接服务器失败')
    }
    return Promise.reject(error);
  }
)

export default service;
