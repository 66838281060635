// 新闻中心权限
const pressRole = {
  fnId: [1, 10, 11, 12, 13],
  // 文章
  article: 1,
  // 标签
  tag: 10,
  // 幻灯片
  slide: 11,
  // 栏目
  column: 12,
  // 日志
  log: 13,
  // 下载报表
  download: 23
};

export default pressRole;