<template>
  <div class="topbar clearfix">
    <div class="sidebar-btn" @click="toggleSidebar">
      <i :class="sidebar.opened ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
    </div>
    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
    <div class="topbar-logout" @click="logout">退出登录</div>
    <div class="topbar-user" @click="myUserInfo">
      <el-avatar
        size="small"
        src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
      ></el-avatar>
      <span>{{userinfo.userName}}</span>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import { mapGetters } from 'vuex'

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'token',
      'userinfo'
    ])
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch("app/toggleSidebar")
    },
    logout() {
      this.$store.dispatch('auth/logout').then(res => {
        this.$router.push('/login')
      })
    },
    myUserInfo() {
      this.$router.push('/account/profile')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
.topbar{
  height:50px;
  position:fixed;
  top:0;
  left:210px;
  right:0;
  background:#fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
  transition-duration: 0.3s;
  z-index:100;
  color:$base-color;
  font-size:14px;
}
.sidebar-btn{
  margin:15px 15px 0 15px;
  float:left;
  i{
    font-size:20px;
  }
}
.breadcrumb-containe{
  float:left;
  
}
.topbar-logout{
  float:right;
  margin:0 15px;
  line-height:50px;
  &:hover{
    color:$hover-color;
    cursor: pointer;
  }
}
.topbar-user{
  float:right;
  line-height:50px;
  cursor:pointer;
  span{
    vertical-align: middle;
    margin-right:5px;
  }
}
.hideSidebar{
  .topbar{
    left:64px;
  }
}
</style>