import Layout from '@/views/layout';
import accountRole from '@/roles/modules/accountRole';

const accountRouter = {
  path: '/account',
  name:'Account',
  redirect: 'noRedirect',
  meta: {icon:'el-icon-user-solid', title: '账户管理',roles: accountRole.fnId,columnId: '3'},
  component: Layout,
  children: [
    {
      path: 'user',
      meta: {title: '用户管理',roles: accountRole.user,columnId: '16'},
      hideChildrenInMenu:true,
      component: () => import('@/views/user'),
      children: [
        {
          path: '',
          name: 'userList',
          hideInMenu: true,
          meta: {roles:  accountRole.user,columnId: `${accountRole.user}`},
          component: () => import('@/views/user/list')
        },
        {
          path: 'detail',
          hideInMenu: true,
          meta: {title: '详情',roles:  accountRole.user,columnId: `${accountRole.user}`},
          component: () => import('@/views/user/detail')
        },
        {
          path: 'edit',
          hideInMenu: true,
          meta: {title: '编辑',roles:  accountRole.user,columnId: `${accountRole.user}`},
          component: () => import('@/views/user/edit')
        }
      ]
    },
    {
      path: 'profile',
      meta: {title: '个人信息',roles: accountRole.profile,columnId: `${accountRole.profile}`},
      hideChildrenInMenu: true,
      component: () => import('@/views/profile'),
      children: [
        {
          path: '',
          name: 'profile',
          component: () => import('@/views/profile/detail'),
          meta: {roles: accountRole.profile, columnId: `${accountRole.profile}`},
        }
      ]
    }
  ]
}

export default accountRouter;
