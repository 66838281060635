// 运营中心权限
const operationRole = {
  fnId: [17,18,19,20],
  // 活动
  activity: {
    fnId: [17,18,19],
    // 新网商峰会
    activity17: 17, 
    // 创新论坛
    activity18: 18,
    // 行业活动
    activity19: 19
  },
  // 资源位
  banner: {
    fnId: [20],
    // 广告位
    banner20: 20, 
  },
  news: 21
};

export default operationRole;