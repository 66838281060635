const getters = {
  token: state => state.auth.token,
  refreshToken: state => state.auth.refreshToken,
  roles: state => state.auth.roles,
  userinfo: state => state.auth.userinfo,
  sidebar: state => state.app.sidebar,
  permission_routers: state => state.permission.routers,
  addRouters: state => state.permission.addRouters,
  menu: state => state.permission.menu,
  cachedViews: state => state.app.cachedViews
  
}
export default getters
