<template>
  <div>  
    <!--只有一级菜单-->
    <el-menu-item v-if="!item.children" :index="item.meta.columnId" :route="{path:basePath}">
      <i v-if="item.meta.icon" :class="item.meta.icon"></i>
      <span slot="title">{{ item.meta.title }}</span>
    </el-menu-item>
    <!--有多级菜单-->
    <el-submenu v-if="item.children" :index="item.meta.columnId" :route="{path:resolvePath(item.path)}">
      <template slot="title">
        <i v-if="item.meta.icon" :class="item.meta.icon"></i>
        <span>{{ item.meta.title }}</span>
      </template>
      <!--递归组件，把遍历的值传回子组件，完成递归调用-->
      <nav-menu  v-for="(route, index) in item.children" :key="index" :item="route" :base-path="resolvePath(route.path)"></nav-menu>
    </el-submenu>

  </div>
</template>

<script>
import path from 'path'
export default {
  name: 'NavMenu',
  props: {
    item: {
      type: Object,
      required: true
    },
    basePath: {
      type: String,
      default:''
    }
  },
  methods: {
    resolvePath(routePath) {
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>
