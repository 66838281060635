<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
        <span
          v-if="item.redirect === 'noRedirect' || index == levelList.length - 1"
          :class="index == levelList.length - 1 ? 'current' : ''"
          >{{ item.meta.title }}</span
        >
        <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
export default {
  data() {
    return {
      levelList: null,
    };
  },
  watch: {
    $route(route) {
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.getBreadcrumb();
      // this.addCachedView()
    },
  },
  created() {
    this.getBreadcrumb();
    // this.addCachedView()
  },
  methods: {
    getBreadcrumb() {
      this.levelList = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      );
    },
    handleLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(path);
    },
    addCachedView() {
      const { name } = this.$route;
      if (name) {
        this.$store.dispatch("app/addCachedView", this.$route);
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  line-height: 50px;
  color: #999;
  .current {
    color: #333;
  }
  a {
    font-weight: normal;
    color: #606266;
    &:hover {
      color: #409eff;
    }
  }
}
</style>
