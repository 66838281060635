import Layout from '@/views/layout';
import operationRole from '@/roles/modules/operationRole';



const operationRouter = {
  path: '/operationcenter',
  name:'Operationcenter',
  redirect: 'noRedirect',
  meta: {icon:'el-icon-s-marketing', title: '运营中心',roles: operationRole.fnId, columnId: '2'},
  component: Layout,
  children: [
    {
      path:'',
      redirect: 'noRedirect',
      meta: {title: '活动管理',roles: operationRole.activity.fnId, columnId: '14'},
      component: () => import('@/views/activity'),
      children: [
        {
          path: 'activity17',
          meta: {title: '新网商峰会',roles: operationRole.activity.activity17,columnId: `${operationRole.activity.activity17}`},
          hideChildrenInMenu: true,
          component: () => import('@/views/activity'),
          children: [
            {
              path: '',
              name: 'activity17List',
              meta: {roles: operationRole.activity.activity17,columnId:`${operationRole.activity.activity17}`},
              component: () => import('@/views/activity/list')
            },
            {
              path: 'detail',
              name: 'activity17Detail',
              meta: {title: '详情',roles: operationRole.activity.activity17,columnId:`${operationRole.activity.activity17}`},
              component: () => import('@/views/activity/detail')
            },
            {
              path: 'edit',
              name: 'activity17Edit',
              meta: {title: '编辑',roles: operationRole.activity.activity17,columnId:`${operationRole.activity.activity17}`},
              component: () => import('@/views/activity/edit')
            }
          ]
        },
        {
          path: 'activity18',
          meta: {title: '创新论坛',roles: operationRole.activity.activity18,columnId: `${operationRole.activity.activity18}`},
          hideChildrenInMenu: true,
          component: () => import('@/views/activity'),
          children: [
            {
              path: '',
              name: 'activity18List',
              meta: {roles: operationRole.activity.activity18,columnId: `${operationRole.activity.activity18}`},
              component: () => import('@/views/activity/list')
            },
            {
              path: 'detail',
              name: 'activity18Detail',
              meta: {title: '详情',roles: operationRole.activity.activity18,columnId: `${operationRole.activity.activity18}`},
              component: () => import('@/views/activity/detail')
            },
            {
              path: 'edit',
              name: 'activity18Edit',
              meta: {title: '编辑',roles: operationRole.activity.activity18,columnId: `${operationRole.activity.activity18}`},
              component: () => import('@/views/activity/edit')
            }
          ]
        },
        {
          path: 'activity19',
          meta: {title: '行业活动',roles: operationRole.activity.activity19,columnId: `${operationRole.activity.activity19}`},
          hideChildrenInMenu: true,
          component: () => import('@/views/activity'),
          children: [
            {
              path: '',
              name: 'activity19List',
              meta: {roles: operationRole.activity.activity19,columnId: `${operationRole.activity.activity19}`},
              component: () => import('@/views/activity/list')
            },
            {
              path: 'detail',
              name: 'activity19Detail',
              meta: {title: '详情',roles: operationRole.activity.activity19,columnId: `${operationRole.activity.activity19}`},
              component: () => import('@/views/activity/detail')
            },
            {
              path: 'edit',
              name: 'activity19Edit',
              meta: {title: '编辑',roles: operationRole.activity.activity19,columnId: `${operationRole.activity.activity19}`},
              component: () => import('@/views/activity/edit')
            }
          ]
        }
      ]
    },
    {
      path:'banner',
      redirect: 'noRedirect',
      meta: {title: '资源位管理',roles: operationRole.banner.fnId,columnId: '15'},
      component: () => import('@/views/banner'),
      children: [
        {
          path: 'banner20',
          meta: {title: '广告位',roles: operationRole.banner.banner20,columnId: `${operationRole.banner.banner20}`},
          hideChildrenInMenu: true,
          component: () => import('@/views/banner'),
          children: [
            {
              path: '',
              name: 'banner1',
              meta: {roles: operationRole.banner.banner20,columnId: `${operationRole.banner.banner20}`},
              component: () => import('@/views/banner/list')
            },
            {
              path: 'detail',
              meta: {title: '详情',roles: operationRole.banner.banner20,columnId: `${operationRole.banner.banner20}`},
              component: () => import('@/views/banner/detail')
            },
            {
              path: 'edit',
              meta: {title: '编辑',roles: operationRole.banner.banner20,columnId: `${operationRole.banner.banner20}`},
              component: () => import('@/views/banner/edit')
            }
          ]
        }
      ]
    },
    {
      path: 'news',
      meta: {title: '业务动态',roles: operationRole.news,columnId: `${operationRole.news}`},
      hideChildrenInMenu:true,
      component: () => import('@/views/article'),
      children: [
        {
          path: '',
          name: 'newsList',
          meta: {roles: operationRole.news, columnId: `${operationRole.news}`},
          component: () => import('@/views/article/list')
        },
        {
          path: 'edit',
          name: 'newsEdit',
          meta: {title: '编辑',roles: operationRole.news, columnId: `${operationRole.news}`},
          component: () => import('@/views/article/edit')
        },
        {
          path: 'detail',
          name: 'newsDetail',
          meta: {title: '详情',roles: operationRole.news, columnId: `${operationRole.news}`},
          component: () => import('@/views/article/detail')
        }
      ]
    }
  ]
}

export default operationRouter;
