import { asyncRouterMap, constantRouterMap } from '@/router'
import { deepClone } from '@/utils/tool'
/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => {
      if(Array.isArray(route.meta.roles)) {
        return route.meta.roles.indexOf(role.columnId) >= 0
      } else {
        return route.meta.roles == role.columnId
      }
    })
  } else {
    return true
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap
 * @param roles
 * @param newsColumn
 */
function filterAsyncRouter(routes, roles, newsColumn) {
    const result = []
    routes.forEach(item => {
      if(hasPermission(roles, item) || (newsColumn && newsColumn.length > 0 && (item.path == 'article' || item.name == 'Presscenter'))){
        let newItem = {...item}
        delete newItem.children
        if(item.path == 'article'){  // 动态添加文章栏目的路由
          newsColumn.forEach(column => {
            let itemCopy = deepClone(item)
            itemCopy.path = item.path+column.columnId
            itemCopy.meta.columnId = `${column.columnId}`
            itemCopy.meta.title = column.columnName
            newItem = itemCopy
            newItem.children.forEach(child => {
              child.name = item.path+column.columnId+child.name
              child.meta.columnId = `${column.columnId}`
            })
            result.push(newItem)
          })
        }else {
          if(item.children && item.children.length){
            newItem.children = filterAsyncRouter(item.children, roles, newsColumn)
          }
          result.push(newItem)
        }
      }
    })
    return result
  }




function filterMenu(routes) {
  const menuData = [];
  routes.forEach(item => {
    if (!item.hideInMenu) {
      const newItem = {...item}
      delete newItem.children
      if (item.children && item.children.length && !item.hideChildrenInMenu) {
        newItem.children = filterMenu(item.children)
      }
      menuData.push(newItem)
    }
  })
  return menuData
}

function getRedirect(item,arr) {
  if(item.path && item.path != '/'){
    arr.push(item.path)
  }
  if(item.children && item.children.length){
    getRedirect(item.children[0],arr)
  }
  return arr
}


const state = {
  routers: constantRouterMap,
  addRouters: [],
  menu: [],
}

const mutations = {
  SET_ROUTERS: (state, routers) => {
    state.addRouters = routers
    state.routers = constantRouterMap.concat(routers)
  },
  SET_MENU: (state, menuData) => {
    state.menu = menuData
  }
}

const actions = {
  GenerateRoutes({commit, dispatch, rootGetters}, data) {
    return new Promise(async resolve => {
      const {roles, newsColumn}  = data.res
      const accessedRouters = filterAsyncRouter(asyncRouterMap, roles, newsColumn)
      const firstMenu = await dispatch('GenerateMenu',accessedRouters)
      const homeRedirect = getRedirect(firstMenu,[]).join('/')
      accessedRouters.unshift({
        path: '/',
        redirect: homeRedirect,
        component: () => import('@/views/layout')
      })
      commit('SET_ROUTERS', accessedRouters)
      resolve()
    })
  },
  // 生成左侧菜单
  GenerateMenu({commit, dispatch, state, rootState}, data) {
    return new Promise(async resolve => {
      const menuData = filterMenu(data)
      commit('SET_MENU', menuData)
      resolve(menuData[0])
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

