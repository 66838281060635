//import Cookies from 'js-cookie'

const state = {
  sidebar: {
    opened: sessionStorage.getItem('sidebarStatus') ? !!+sessionStorage.getItem('sidebarStatus') : true
  },
  cachedViews: []
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    if (state.sidebar.opened) {
      sessionStorage.setItem('sidebarStatus',1)
    } else {
      sessionStorage.setItem('sidebarStatus',0)
    }
  },
  ADD_CACHED_VIEW: (state, view) => {
    if (state.cachedViews.includes(view.name)) return
    // if (!view.meta.noCache) {
      state.cachedViews.push(view.name)
    // }
  },
}

const actions = {
  toggleSidebar({commit}) {
    commit('TOGGLE_SIDEBAR')
  },
  addCachedView({commit}, view) {
    commit('ADD_CACHED_VIEW', view)
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}