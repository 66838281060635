import request from '@/utils/request'

// 登录
export function login(data) {
  return request({
    url: '/api/auth/token',
    method: 'post',
    data
  })
}

// 获取用户信息、权限
export function getUserInfo() {
  return request({
    url: '/api/auth/userAuthList',
    method: 'get'
  })
}

// 角色下拉列表
export function getRoles(params) {
  return request({
    url: '/api/auth/role/dropdownList',
    method: 'get',
    params
  })
}

// 刷新token
export function refreshToken(data) {
  return request({
    url: '/api/auth/refreshToken',
    method: 'post',
    data
  })
}