import Layout from "@/views/layout";
import pressRole from "@/roles/modules/pressRole";

const pressRouter = {
  path: "/presscenter",
  name: "Presscenter",
  redirect: "noRedirect",
  meta: {
    icon: "el-icon-s-order",
    title: "新闻中心",
    roles: pressRole.fnId,
    columnId: "1",
  },
  component: Layout,
  children: [
    {
      path: "article",
      meta: { title: "文章", roles: pressRole.article },
      hideChildrenInMenu: true,
      component: () => import("@/views/article"),
      children: [
        {
          path: "",
          name: "List",
          meta: { roles: pressRole.article },
          component: () => import("@/views/article/list"),
        },
        {
          path: "edit",
          name: "Edit",
          meta: { title: "编辑", roles: pressRole.article },
          component: () => import("@/views/article/edit"),
        },
        {
          path: "detail",
          name: "Detail",
          meta: { title: "详情", roles: pressRole.article },
          component: () => import("@/views/article/detail"),
        },
      ],
    },
    {
      path: "slide",
      meta: {
        title: "幻灯片",
        roles: pressRole.slide,
        columnId: `${pressRole.slide}`,
      }, // title用于菜单和面包屑的栏目显示文字，roles：匹配用户权限，columnId：栏目的唯一标识，在菜单选中定位有用到
      hideChildrenInMenu: true,
      component: () => import("@/views/banner"),
      children: [
        {
          path: "",
          name: "slideList",
          meta: { roles: pressRole.slide, columnId: `${pressRole.slide}` },
          component: () => import("@/views/banner/list"),
        },
        {
          path: "detail",
          meta: {
            title: "详情",
            roles: pressRole.slide,
            columnId: `${pressRole.slide}`,
          },
          component: () => import("@/views/banner/detail"),
        },
        {
          path: "edit",
          meta: {
            title: "编辑",
            roles: pressRole.slide,
            columnId: `${pressRole.slide}`,
          },
          component: () => import("@/views/banner/edit"),
        },
      ],
    },

    {
      path: "column",
      name: "Column",
      meta: {
        title: "栏目管理",
        roles: pressRole.column,
        columnId: `${pressRole.column}`,
      },
      component: () => import("@/views/column"),
    },
    {
      path: "download",
      name: "Download",
      meta: {
        title: "下载报表",
        roles: pressRole.download,
        columnId: `${pressRole.download}`,
      },
      component: () => import("@/views/download"),
    },
    {
      path: "log",
      name: "Log",
      meta: {
        title: "操作日志",
        roles: pressRole.log,
        columnId: `${pressRole.log}`,
      },
      component: () => import("@/views/log"),
    },
    {
      path: "tag",
      name: "Tag",
      meta: {
        title: "标签管理",
        roles: pressRole.tag,
        columnId: `${pressRole.tag}`,
      },
      component: () => import("@/views/tag"),
    },
  ],
};

export default pressRouter;
