import { login, getUserInfo, refreshToken} from '@/api/auth'
import { resetRouter } from '@/router'

const state = {
  token: localStorage.getItem('token'),
  refreshToken: localStorage.getItem('refreshToken'),
  roles: [],
  userinfo:{},
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_REFRESHTOKEN: (state, token) => {
    state.refreshToken = token
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_NEWSCOLUMNS: (state, newsColumn) => {
    state.newsColumn = newsColumn
  },
  SET_USERINFO: (state, userinfo) => {
    state.userinfo = userinfo
  }
}

const actions = {
  // user login
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      login(data).then(response => {
        commit('SET_TOKEN', response.data.accessToken)
        commit('SET_REFRESHTOKEN', response.data.accessToken)
        localStorage.setItem('token',response.data.accessToken)
        localStorage.setItem('refreshToken',response.data.refreshToken)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  getUserInfo({commit,dispatch}) {
    return new Promise(async (resolve, reject) => {
      //dispatch('logout')
      getUserInfo().then(response => {
        commit('SET_ROLES', response.data.authColumn)
        commit('SET_NEWSCOLUMNS', response.data.newsColumn)
        commit('SET_USERINFO', response.data.user)
        resolve({roles:response.data.authColumn,newsColumn:response.data.newsColumn})
      }).catch(error => {
        reject(error)
      })
    })
  },
  // logout
  logout({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_REFRESHTOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_NEWSCOLUMNS', [])
      commit('SET_USERINFO', {})
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      resetRouter()
      resolve()
    })
  },
  refreshToken({ commit }, data) {
    return new Promise(resolve => {
      commit('SET_TOKEN', data.data.accessToken)
      commit('SET_REFRESHTOKEN', data.data.refreshToken)
      localStorage.setItem('token', data.data.accessToken)
      localStorage.setItem('refreshToken', data.data.refreshToken)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
