<template>
  <div class="app-wrapper" :class="sidebar.opened ? '' : 'hideSidebar'">
    <sidebar></sidebar>
    <topbar></topbar>
    <div class="main-container">
      <keep-alive :include="cachedViews">
        <router-view :key="key"></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import Sidebar from './components/Sidebar'
import Topbar from './components/Topbar'
import { mapGetters } from 'vuex'

export default {
  components: {
    Sidebar,
    Topbar
  },
  computed: {
    ...mapGetters(['sidebar','cachedViews']),
    key() {
      return this.$route.fullPath
    }
  }
}
</script>
